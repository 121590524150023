<script>
import Layout from "../../layouts/main";
import Breadcrumb from '../../../components/breadcrumb.vue';
import Currency from '../../../components/currency.vue';
import VuePhoneNumberInput from "vue-phone-number-input";
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import DatePicker from "vue2-datepicker";
import PaymentMethodModal from '../../../components/payment-method-modal.vue';
import Common from '../../../helpers/Common';
import cloneDeep from 'lodash.clonedeep';
import draggable from 'vuedraggable';
import { required, email, maxLength } from "vuelidate/lib/validators";
import CloseModalBtn from "../../../components/close-modal-btn.vue";
import Country from '../../../components/country.vue';

import {
	CREATE_INVOICES,
    FETCH_INVOICES_DETAILS,
    FETCH_INVOICES_TEMPLATE_DETAILS,
    FETCH_MERCHANT_ENTITY_DETAILS
} from "@/state/actions.type";
import moment from 'moment';
import InvoicePdfPreview from './invoice-pdf-preview.vue';

export default {
    components: {
        Layout,
        Breadcrumb,
        Currency,
        DatePicker,
        VuePhoneNumberInput,
        PaymentMethodModal,
        draggable,
        CloseModalBtn,
        Country,
        InvoicePdfPreview
    },
    validations: {
        form: {
            customer_details: {
                name: { required },
                email_address: { required, email },
                phone_number: { }
            },
            merchant_invoice_ref: { required },
            invoice_date: { required },
            due_date: { required },
            description: { maxLength: maxLength(200) },
        }
    },
    data() {
        return {
            form: {
                customer_details: {
                    name: "",
                    email_address: "",
                    phone_number: ""
                },
                currency: "",
                country_code: "",
                billing_details: {
                    billing_address: {
                        city: "",
                        country_code: "",
                        locale: "",
                        line_1: "",
                        line_2: "",
                        postal_code: "",
                        state: "",
                        country_name: ""
                    }
                },
                shipping_details: {
                    shipping_address: {
                        city: "",
                        country_code: "",
                        locale: "",
                        line_1: "",
                        line_2: "",
                        postal_code: "",
                        state: "",
                        country_name: ""
                    }
                },
                merchant_details: {
                    back_url: "",
                    logo: "",
                    name: "",
                    promo_code: "NA",
                    promo_discount: 0,
                    shipping_charges: 0
                },
                source: "default",
                notify_by_phone: false,
                notify_by_email: true,
                send_email_confirmation:false,
                merchant_invoice_ref: `${new Date().getTime()}`,
                invoice_date: "",
                due_date: "",
                description: "",
                notes: [],
                chosen_payment_methods: [],
                show_qr_code: false,
                scheduled_date: "",
                send_immediately: true,
                reminder_time: null,
                reminder_time_unit: "",
                order_details: [
                    {
                        id: "1",
                        name: "",
                        quantity: null,
                        price: null
                    }
                ],
                additional_costs: [],
                sub_total: 0,
                amount: 0,
                customer_note_title:"Customer Note",
                customer_note_value:"",
                additional_invoice_notes:[],
                
                terms_and_conditions: "",
                success_url: "",
                failure_url: "",
                show_shipping_details: false
            },
            editing: false,
            editedCustomerNoteTitle: '', // Bind this to the input field
            customerNoteTitle: 'Customer Note', // Your initial customer note title
            additionalNotes: [],
            scheduleInvoice: false,
            add_fields_error: false,
            add_fields_error1: false,
            showCustomFields: false,
            setReminder: false,
            phoneNumber: null,
            timeOptions: [],
            unitOptions: [{ value: "hours_before_due_date", disabled: true, text: "Hours before due" }, { value: "days_before_due_date", disabled: true, text: "Days before due" }, { value: "hours_after_due_date", disabled: false, text: "Hours after due" }, { value: "days_after_due_date", disabled: false, text: "Days after due" }],
            additionalCost: {
                name: "",
                type: "CHARGE",
                amount: 0,
                visible: false,
                percent: true
            },
            itemDetailsError: false,
            pdfHeight:"",
            billing_address: {
                city: "",
                country_code: "",
                locale: "",
                line_1: "",
                line_2: "",
                postal_code: "",
                state: "",
                country_name: ""
            },
            shipping_address: {
                city: "",
                country_code: "",
                locale: "",
                line_1: "",
                line_2: "",
                postal_code: "",
                state: "",
                country_name: ""
            },
            // showReminderError: false
        }
    },
    filters: {
        date(date) {
            if(!date) {
                return "";
            }
            return moment(date).format("DD MMM, YYYY")
        }
    },
    computed: {
        items() {
            return [
                {
                    text: "Invoices",
                    to: { name: 'Invoices' }
                },
                {
                    text: "Create New Invoice",
                    active: true
                }
            ];
        },
        userDetails() {
            return this.$store.state.auth.currentUser;
        },
        subTotal() {
            return this.form.order_details.reduce(((total, element) => total + Number((element.price * element.quantity).toFixed(2))), 0);
        },
        reminderTimeUnit() {
            if(this.form.reminder_time_unit) {
                let unit = this.unitOptions.find(el => el.value == this.form.reminder_time_unit);
                return unit.text;
            }
            return null;
        },
        currencySymbol() {
            return (0).toLocaleString("en-US", { style: 'currency', currency: this.form.currency }).replace(/\d./g, '').trim();
        },
        billingAddress() {
            let address = "";
            if(this.form.billing_details.billing_address.line_1) {
                address += this.form.billing_details.billing_address.line_1;
            }
            if(this.form.billing_details.billing_address.line_2) {
                if(address) {
                    address += ", ";
                }
                address += this.form.billing_details.billing_address.line_2;
            }
            if(this.form.billing_details.billing_address.city) {
                if(address) {
                    address += ", ";
                }
                address += this.form.billing_details.billing_address.city;
            }
            if(this.form.billing_details.billing_address.country_name) {
                if(address) {
                    address += ", ";
                }
                address += this.form.billing_details.billing_address.country_name;
            }
            if(this.form.billing_details.billing_address.postal_code) {
                if(address) {
                    address += ", ";
                }
                address += `Postal Code: ${this.form.billing_details.billing_address.postal_code}`;
            }
            return address;
        },
        shippingAddress() {
            let address = "";
            if(this.form.shipping_details.shipping_address.line_1) {
                address += this.form.shipping_details.shipping_address.line_1;
            }
            if(this.form.shipping_details.shipping_address.line_2) {
                if(address) {
                    address += ", ";
                }
                address += this.form.shipping_details.shipping_address.line_2;
            }
            if(this.form.shipping_details.shipping_address.city) {
                if(address) {
                    address += ", ";
                }
                address += this.form.shipping_details.shipping_address.city;
            }
            if(this.form.shipping_details.shipping_address.country_name) {
                if(address) {
                    address += ", ";
                }
                address += this.form.shipping_details.shipping_address.country_name;
            }
            if(this.form.shipping_details.shipping_address.postal_code) {
                if(address) {
                    address += ", ";
                }
                address += `Postal Code: ${this.form.shipping_details.shipping_address.postal_code}`;
            }
            return address;
        }
    },
    created() {
        this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`);
        this.form.currency = this.$route.query.lastCurrency ? this.$route.query.lastCurrency : Common.currencyByCountry(this.userDetails.country);
        this.form.country_code = this.$route.query.lastCountry ? this.$route.query.lastCountry : this.userDetails.country;
        if(this.$route.query.invoiceRef) {
            this.$store.dispatch(`invoices/${FETCH_INVOICES_DETAILS}`, {
                ref: this.$route.query.invoiceRef,
                submerchantKey: ''
            }).then(response => {
                if(response.content) {
                    const content = response.content;

                    this.form.customer_details = {
                        name: content.customer_name,
                        email_address: content.customer_email_address,
                        phone_number: "",
                    }
                    if(content.customer_phone_number && isValidPhoneNumber(content.customer_phone_number)) {
                        const phoneNumber = parsePhoneNumber(content.customer_phone_number);
                        this.phoneNumber = {
                            "countryCode": phoneNumber.country,
                            "isValid": phoneNumber.isValid(),
                            "phoneNumber": phoneNumber.nationalNumber,
                            "countryCallingCode": phoneNumber.countryCallingCode,
                            "formattedNumber": phoneNumber.number,
                            "nationalNumber": phoneNumber.nationalNumber
                        }

                        this.form.customer_details.phone_number = phoneNumber.nationalNumber;
                    }

                    this.form.currency = content.currency;
                    // this.form.send_immediately = content.send_immediately;
                    this.form.send_email_confirmation = content.send_email_confirmation;
                    this.form.country_code = content.countryCode;
                    this.form.billing_details.billing_address = content.billing_details.billing_address;
                    this.form.shipping_details.shipping_address = content.shipping_details.shipping_address;

                    this.form.notify_by_phone = content.notify_by_phone;
                    this.form.description = content.description;
                    if(content.notes && content.notes.length > 0) {
                        this.showCustomFields = true;
                        this.form.notes = content.notes;
                    }
                    this.form.show_qr_code = content.show_qr_code;
                    if(content.order_details && content.order_details.length > 0) {
                        this.form.order_details = [];
                        content.order_details.map((item, index) => {
                            this.form.order_details.push({
                                id: `${index}`,
                                name: item.name,
                                quantity: item.quantity,
                                price: item.price
                            });
                        });
                    }
                    if(content.additional_costs && content.additional_costs.length > 0) {
                        this.form.additional_costs = content.additional_costs.map(cost => {
                            cost.percent = (cost.charge_nature === "PERCENT");
                            return cost;
                        });
                    }
                    // this.form.sub_total = content.sub_total;
                    // this.form.amount = content.total_amount;
                    this.form.customer_note_title = content.customer_note_title;
                    this.form.customer_note_value = content.customer_note_value;
                    this.form.terms_and_conditions = content.terms_and_conditions;
                    if(content.additional_invoice_notes && content.additional_invoice_notes.length > 0){
                        this.form.additional_invoice_notes = content.additional_invoice_notes;
                    }else{
                        this.form.additional_invoice_notes = [];
                    }
                    this.form.success_url = content.success_url;
                    this.form.failure_url = content.failure_url;
                    this.form.show_shipping_details = content.show_shipping_details;
                }
            }).catch(error => {
                console.log(error);
            });

        } else if(this.$route.query.templateRef) {
            this.$store.dispatch(`invoices/${FETCH_INVOICES_TEMPLATE_DETAILS}`, this.$route.query.templateRef).then(response => {
                // if(response.order_items && response.order_items.length > 0) {
                    this.form.description = response.description;
                    this.form.terms_and_conditions = response.terms_and_conditions;
                    this.form.customer_note_title = response.customer_note_title;
                    this.form.customer_note_value = response.customer_note_value;
                    this.form.show_qr_code = response.show_qr_code;
                    this.form.send_immediately = response.send_immediately;
                    this.form.send_email_confirmation = response.send_email_confirmation;
                    if(response.currency){
                        this.form.currency = response.currency;
                    }
                    if(response.additional_invoice_notes && response.additional_invoice_notes.length > 0){
                        this.form.additional_invoice_notes = response.additional_invoice_notes;
                    }else{
                        this.form.additional_invoice_notes = [];
                    }

                    // if(response.reminders && response.reminders.length > 0) {
                    //     this.setReminder = true;
                    //     this.form.reminder_time = response.reminders[0].reminder_time;
                    //     this.form.reminder_time_unit = response.reminders[0].reminder_time_unit;
                    // }

                    if(response.order_items && response.order_items.length > 0) {
                        this.form.order_details = [];
                        response.order_items.map((item, index) => {
                            this.form.order_details.push({
                                id: `${index}`,
                                name: item.name,
                                quantity: item.quantity,
                                price: item.price
                            });
                        });
                    }

                    this.form.additional_costs = [];
                    if(response.additional_costs && response.additional_costs.length > 0) {
                        this.form.additional_costs = response.additional_costs.map(cost => {
                            cost.percent = (cost.charge_nature === "PERCENT");
                            return cost;
                        });
                    }
                // }

                if(response.notes && response.notes.length > 0) {
                    this.showCustomFields = true;
                    this.form.notes = response.notes;
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    },
    watch: {
        'form.send_immediately'(value) {
            if(value) {
                this.scheduleInvoice = false;
                this.form.scheduled_date = "";
            }
        },
        setReminder(value) {
            if(!value) {
                this.form.reminder_time = null;
                this.form.reminder_time_unit = "";
            }
        },
        scheduleInvoice(value) {
            if(value) {
                this.form.send_immediately = false;
            } else {
                this.form.scheduled_date = "";
            }
        },
        'form.show_shipping_details'(value) {
            if(value) {
                this.shipping_address.country_code = this.billing_address.country_code;
                this.shipping_address.country_name = this.billing_address.country_name;
            } else {
                this.shipping_address = {
                    city: "",
                    country_code: "",
                    locale: "",
                    line_1: "",
                    line_2: "",
                    postal_code: "",
                    state: "",
                    country_name: ""
                };
            }
        }
    },
    methods: {
        updatePhoneNumber(e) {
            this.phoneNumber = e;
            this.form.customer_details.phone_number = e.phoneNumber;
            if(!e.phoneNumber) {
                this.form.notify_by_phone = false;
            }
        },
        storePaymentMethods(value) {
            this.form.chosen_payment_methods = value;
        },
        startEditing() {
            this.editedCustomerNoteTitle = "";
            this.editing = true;
        },
        resetFields() {
            this.editing = false;
            this.editedCustomerNoteTitle = "Customer Note";
            this.form.customer_note_title = "Customer Note";
            
        },
        saveChanges() {
            //this.customerNoteTitle = this.editedCustomerNoteTitle;
            this.form.customer_note_title = this.editedCustomerNoteTitle;
            this.editing = false;
            // You can emit an event here to notify the parent component about the changes
        },
        cancelEditing() {
            this.editing = false;
        },
        addNote() {
            if(!this.checkFieldsError1()) {
                this.form.additional_invoice_notes.push({ title: '', description: '' });
            }
        },
        checkFieldsError1() {
            let emptyFields = this.form.additional_invoice_notes.filter((el) => !el.description || !el.title);
            if(emptyFields.length > 0) {
                this.add_fields_error1 = true;
            } else {
                this.add_fields_error1 = false;
            }
            return this.add_fields_error1;
        },
        removeNote(index) {
            this.form.additional_invoice_notes.splice(index, 1);
        },
        customFieldsToggle(value) {
            if(value) {
                this.form.notes = [{
                    key: "",
                    value: "",
                    show_note: false
                }]
            } else {
                this.form.notes = [];
            }
        },
        reset_add_field_error() {
            if(this.add_fields_error) {
                this.checkFieldsError();
            }
        },
        checkFieldsError() {
            let emptyFields = this.form.notes.filter((el) => !el.key || !el.value);
            if(emptyFields.length > 0) {
                this.add_fields_error = true;
            } else {
                this.add_fields_error = false;
            }
            return this.add_fields_error;
        },
        addCustomField() {
            if(!this.checkFieldsError()) {
                this.form.notes.push({
                    key: "",
                    value: "",
                    show_note: false
                });
            }
        },
        diffHours(dt2, dt1) {
            var diff =(dt2.getTime() - dt1.getTime()) / 1000;
            diff /= (60 * 60);
            return Math.abs(Math.round(diff));
        },
        dueDateChanged(type) {
            // this.showReminderError = false;
            if(!type) {
                this.unitOptions[0].disabled = true;
                this.unitOptions[1].disabled = true;
                return;
            }
            this.unitOptions[0].disabled = false;

            this.setOptions(type);

            // if(this.form.reminder_time !== null && !this.timeOptions.includes(this.form.reminder_time)) {
            //     this.showReminderError = true;
            // }

            // if(this.form.reminder_time_unit !== "") {
            //     let unit = this.unitOptions.find(option => option.value === this.form.reminder_time_unit)
            //     if(!unit || unit.disabled) {
            //         this.showReminderError = true;
            //     }
            // }
        },
        setOptions(type) {
            if(type === 'hours_after_due_date') {
                this.timeOptions = Array(23).fill().map((x,i) => i + 1);
                return;
            }
            if(type === 'days_after_due_date') {
                this.timeOptions = Array(5).fill().map((x,i) => i + 1);
                return;
            }

            let firstDate = new Date();
            let lastDate = this.form.due_date;

            let diffInhours = this.diffHours(firstDate, lastDate);
            if(diffInhours > 30) {
                if(type == 'hours_before_due_date') {
                    this.timeOptions = Array(23).fill().map((x,i) => i + 1);
                } else {
                    let daysCount = Math.floor(diffInhours / 24);
                    this.timeOptions =  Array(daysCount).fill().map((x,i) => i + 1);
                }
                this.unitOptions[1].disabled = false;
            } else {
                this.unitOptions[1].disabled = true;
                this.timeOptions =  Array(diffInhours - 1).fill().map((x,i) => i + 1);
            }
        },
        timeChanged(value) {
            this.form.reminder_time = value;
        },
        unitChanged(unit) {
            this.form.reminder_time = null;
            this.setOptions(unit.value);
            this.form.reminder_time_unit = unit.value;
        },
        changeCurrency(value) {
            this.form.currency = value;
            this.form.country_code = Common.countryByCurrency(value);
        },
        addItem() {
            this.form.order_details.push({
                id: `${this.form.order_details.length + 1}`,
                name: "",
                quantity: null,
                price: null
            });
        },
        removeItem(index) {
            if(this.form.order_details.length > 1) {
                this.form.order_details.splice(index, 1);
            } else {
                this.$notify({
                    type: "error",
                    text: "Atleast One Item is required",
                    closeOnClick: true,
                })
            }
        },
        removeCustomField(index) {
            this.form.notes.splice(index, 1);
        },
        cloneItem(index) {
            this.form.order_details.push(cloneDeep(this.form.order_details[index]));
        },
        addCost() {
            this.additionalCost.visible = true;
        },
        countDecimalPlaces(number) {
            const decimalIndex = number.toString().indexOf('.');
            return decimalIndex >= 0 ? number.toString().length - decimalIndex - 1 : 0;
        },
        addAdditionalCost() {
            if(!this.additionalCost.name || this.additionalCost.amount == null) {
                this.$notify({
                    type: "error",
                    text: "Name and amount is required for adding Discount/Charge/Tax.",
                    closeOnClick: true,
                });
                return false;
            }
            if(!this.additionalCost.percent && this.countDecimalPlaces(this.additionalCost.amount) > 2) {
                this.additionalCost.amount = parseFloat(this.additionalCost.amount.toFixed(2));
            }
            const cost = {
                name: this.additionalCost.name,
                type: this.additionalCost.type,
                amount: this.additionalCost.amount,
                percent: this.additionalCost.percent
            };

            if(this.additionalCost.index >= 0) {
                this.form.additional_costs[this.additionalCost.index] = cost;
            } else {
                this.form.additional_costs.push(cost);
            }

            this.additionalCost = {
                name: "",
                type: "CHARGE",
                amount: 0,
                visible: false,
                percent: true
            }
        },
        resetAdditionalCost() {
            this.additionalCost = {
                name: "",
                type: "CHARGE",
                amount: 0,
                visible: false,
                percent: true
            }
        },
        saveInvoiceDetails() {
            this.form.order_details.map((el) => {
                if(!el.name || !el.quantity || el.quantity <= 0 || (el.price !== 0 && !el.price) || el.price < 0) {
                    this.itemDetailsError = true;
                } else {
                    this.itemDetailsError = false;
                }
            });

            this.$v.form.$touch();
            // (this.showReminderError && this.setReminder) ||
            if (this.$v.form.$anyError || this.itemDetailsError || (this.phoneNumber && this.phoneNumber.formattedNumber && !this.phoneNumber.isValid)) {
                if(this.phoneNumber && this.phoneNumber.formattedNumber && !this.phoneNumber.isValid) {
                    this.$notify({
                        type: "error",
                        text: "Phone number is Invalid",
                        closeOnClick: true,
                    });
                }
                return;
            }

            let payload = cloneDeep(this.form);
            payload.merchant_details.name = this.$store.state.merchant.entityDetails.brand_name;
            payload.success_url = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/success.html`;
            payload.failure_url = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/failure.html`;
            if(['VND', 'KRW', 'IDR', 'TWD', 'JPY'].includes(this.form.currency)) {
                payload.sub_total = Math.round(this.subTotal);
                payload.amount = Math.round(this.totalAmount());
                payload.additional_costs = this.form.additional_costs.map((cost) => {
                    return {
                        name: cost.name,
                        type: cost.type,
                        charge_nature: cost.percent ? "PERCENT" : "FLAT",
                        amount: cost.percent ? cost.amount : Math.round(cost.amount)
                    }
                });
            } else {
                payload.sub_total = parseFloat(this.subTotal.toFixed(2));
                payload.amount = parseFloat(this.totalAmount().toFixed(2));
                payload.additional_costs = this.form.additional_costs.map((cost) => {
                    return {
                        name: cost.name,
                        type: cost.type,
                        charge_nature: cost.percent ? "PERCENT" : "FLAT",
                        amount: cost.percent ? cost.amount : parseFloat((cost.amount).toFixed(2))
                    }
                });
            }
            payload.invoice_date = moment(this.form.invoice_date).toISOString();
            payload.due_date = moment(this.form.due_date).toISOString();
            payload.scheduled_date = moment(this.form.scheduled_date).toISOString();
            payload.customer_details.phone_number = this.phoneNumber && this.phoneNumber.formattedNumber ? this.phoneNumber.formattedNumber : "";

            this.$store.dispatch(`invoices/${CREATE_INVOICES}`, payload) 
            .then((response) => {
                this.$notify({
                    type: "success",
                    text: response.Message ? response.Message : "Invoice Created Successfully",
                    closeOnClick: true,
                });
                this.$router.push({name: 'Invoices'});
            })
            .catch((error) => {
                // error
                console.log("error", error);
                const errorObject = JSON.parse(error.message)
                this.$notify({
                    type: "error",
                    text: errorObject.message ? errorObject.message : 'Failed To Create Invoice.',
                    closeOnClick: true,
                });
            });
        },
        validateState(name, key) {
            const { $dirty, $error } = key ? this.$v.form[key][name] : this.$v.form[name];
            return $dirty ? !$error : null;
        },
        editAdditionalCost(index) {
            this.additionalCost = { ...this.form.additional_costs[index], visible: true, index: index };
        },
        removeAdditionalCost(index) {
            this.form.additional_costs.splice(index, 1);
        },
        billingAddressCountryChanged(value) {
            this.billing_address.country_code = value;
            this.billing_address.country_name = new Intl.DisplayNames(['en'], {type: 'region'}).of(value);
        },
        shippingAddressCountryChanged(value) {
            this.shipping_address.country_code = value;
            this.shipping_address.country_name = new Intl.DisplayNames(['en'], {type: 'region'}).of(value);
        },
        // disabledBeforeToday(date) {
        //     return date < new Date().setHours(0, 0, 0, 0);
        // },
        disabledBeforeInvoiceDate(date) {
            return date < new Date(this.form.invoice_date);
        },
        disabledAfterDueDate(date) {
            return date < new Date().setHours(0,0,0,0) || date > new Date(this.form.due_date);
        },
        totalAmount() {
            if(['VND', 'KRW', 'IDR', 'TWD', 'JPY'].includes(this.form.currency)) {
                return this.subTotal + this.form.additional_costs.reduce(((total, element) => {
                    if(element.type == 'CHARGE') {
                        if(element.percent) {
                            total += Math.round(element.amount * this.subTotal / 100);
                        } else {
                            total += Math.round(element.amount);
                        }
                    } else {
                        if(element.percent) {
                            total -= Math.round(element.amount * this.subTotal / 100);
                        } else {
                            total -= Math.round(element.amount);
                        }
                    }
                    return total;
                }), 0);
            } else {
                return this.subTotal + this.form.additional_costs.reduce(((total, element) => {
                    if(element.type == 'CHARGE') {
                        if(element.percent) {
                            total += parseFloat((element.amount * this.subTotal / 100).toFixed(2));
                        } else {
                            total += parseFloat((element.amount).toFixed(2));
                        }
                    } else {
                        if(element.percent) {
                            total -= parseFloat((element.amount * this.subTotal / 100).toFixed(2));
                        } else {
                            total -= parseFloat((element.amount).toFixed(2));
                        }
                    }
                    return total;
                }), 0);
            }
        },
        saveAddress() {
            this.form.billing_details.billing_address = {
                city: this.billing_address.city,
                country_code: this.billing_address.country_code,
                locale: this.billing_address.locale,
                line_1: this.billing_address.line_1,
                line_2: this.billing_address.line_2,
                postal_code: this.billing_address.postal_code,
                state: this.billing_address.state,
                country_name: this.billing_address.country_name
            };
            this.form.shipping_details.shipping_address = {
                city: this.shipping_address.city,
                country_code: this.shipping_address.country_code,
                locale: this.shipping_address.locale,
                line_1: this.shipping_address.line_1,
                line_2: this.shipping_address.line_2,
                postal_code: this.shipping_address.postal_code,
                state: this.shipping_address.state,
                country_name: this.shipping_address.country_name
            };
            this.$bvModal.hide('address-modal');
        },
        resetAddress() {
            this.billing_address = {
                city: this.form.billing_details.billing_address.city,
                country_code: this.form.billing_details.billing_address.country_code,
                locale: this.form.billing_details.billing_address.locale,
                line_1: this.form.billing_details.billing_address.line_1,
                line_2: this.form.billing_details.billing_address.line_2,
                postal_code: this.form.billing_details.billing_address.postal_code,
                state: this.form.billing_details.billing_address.state,
                country_name: this.form.billing_details.billing_address.country_name
            };
            if(!this.billing_address.country_code) {
                this.billing_address.country_code = this.userDetails.country;
                this.billing_address.country_name = new Intl.DisplayNames(['en'], {type: 'region'}).of(this.userDetails.country);
            }

            this.shipping_address = {
                city: this.form.shipping_details.shipping_address.city,
                country_code: this.form.shipping_details.shipping_address.country_code,
                locale: this.form.shipping_details.shipping_address.locale,
                line_1: this.form.shipping_details.shipping_address.line_1,
                line_2: this.form.shipping_details.shipping_address.line_2,
                postal_code: this.form.shipping_details.shipping_address.postal_code,
                state: this.form.shipping_details.shipping_address.state,
                country_name: this.form.shipping_details.shipping_address.country_name
            };
            if(!this.shipping_address.country_code) {
                this.shipping_address.country_code = this.userDetails.country;
                this.shipping_address.country_name = new Intl.DisplayNames(['en'], {type: 'region'}).of(this.userDetails.country);
            }

            if(this.form.shipping_details.shipping_address.country_code) {
                this.form.show_shipping_details = true;
            } else {
                this.form.show_shipping_details = false;
            }
        },
    }

}
</script>

<template>
    <Layout>
        <div class="d-flex justify-content-between">
            <breadcrumb :items="items" :goBackTo="{ name: 'Invoices' }" />
            <div class="d-flex" style="gap: 10px;">
                <b-btn variant="primary" @click="$router.push({name: 'Invoices'})" class="bg-white text-dark">Cancel</b-btn>
                <b-btn variant="primary" v-b-modal.preview-modal class="bg-white text-dark">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.9999 13.5138C10.3532 13.5138 12.5466 12.1271 14.0732 9.72714C14.6732 8.78714 14.6732 7.20714 14.0732 6.26714C12.5466 3.86714 10.3532 2.48047 7.9999 2.48047C5.64656 2.48047 3.45323 3.86714 1.92656 6.26714C1.32656 7.20714 1.32656 8.78714 1.92656 9.72714C3.45323 12.1271 5.64656 13.5138 7.9999 13.5138Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Preview
                </b-btn>
                <b-btn variant="primary" @click="saveInvoiceDetails">Create Invoice</b-btn>
            </div>
        </div>
        <form @submit.prevent method="POST" autocomplete="off" novalidate>
            <div class="d-flex" style="gap: 25px; padding-top: 25px; padding-bottom: 25px;">
                <div class="main-section flex-fill">
                    <div class="section-header">
                        <p class="section-title">Create New Invoice</p>
                        <p class="section-text">Create New Invoice to collect payment from your customers.</p>
                    </div>

                    <div style="padding: 25px;">
                        <b-input-group style="gap: 8px;">
                            <b-form-group
                                class="flex-fill"
                                label-for="customer-name"
                            >
                                <template #label>
                                    Customer Name<span class="text-danger">*</span>
                                </template>
                                <b-form-input :state="validateState('name', 'customer_details')" id="customer-name" name="customer-name" v-model="form.customer_details.name"></b-form-input>
                                <b-form-invalid-feedback id="customer-name-live-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group
                                label="Currency"
                                label-for="currency"
                            >
                                <currency v-model="form.currency" :currency="form.currency" @currency-changed="changeCurrency" />
                            </b-form-group>
                        </b-input-group>

                        <b-form-group
                            label="Email"
                            label-for="email"
                        >
                            <template #label>
                                Email<span class="text-danger">*</span>
                            </template>
                            <b-form-input id="email" name="email" :state="validateState('email_address', 'customer_details')" type="email" v-model="form.customer_details.email_address"></b-form-input>
                            <b-form-invalid-feedback id="email-live-feedback">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>

                        <b-input-group class="justify-content-between">
                            <b-form-group
                                class="w-50"
                                label="Contact Number"
                                label-for="contact-number"
                            >
                                <VuePhoneNumberInput
                                    id="contact-number"
                                    :class="{'is-invalid': $v.form.customer_details.phone_number.$error}"
                                    :value="form.customer_details.phone_number"
                                    @update="updatePhoneNumber($event)"
                                    :default-country-code="phoneNumber && phoneNumber.countryCode ? phoneNumber.countryCode : userDetails.country"
                                    :preferred-countries="['IN', 'ID', 'MY', 'PH', 'SG', 'KR', 'TH', 'VN']"
                                />
                                <b-form-invalid-feedback id="contact-number-live-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                            <b-btn v-b-modal.address-modal class="text-chai my-auto p-0" size="sm" variant="link">
                                + Add Billing/Shipping Address
                            </b-btn>
                        </b-input-group>
                        <b-form-group>
                            <b-form-checkbox
                                name="send_sms"
                                :value="true"
                                :unchecked-value="false"
                                :disabled="!form.customer_details.phone_number"
                                v-model="form.notify_by_phone"
                            >
                                Send invoice via SMS ?
                            </b-form-checkbox>
                        </b-form-group>
                        <div class="row">
                            <div class="col" v-show="form.billing_details.billing_address.line_1 || form.billing_details.billing_address.line_2">
                                <label>Billing Address</label>
                                <p class="address pb-0">
                                    {{ billingAddress }}
                                </p>
                            </div>
                            <div class="col" v-show="form.show_shipping_details && (form.shipping_details.shipping_address.line_1 || form.shipping_details.shipping_address.line_2)">
                                <label>Shipping Address</label>
                                <p class="address pb-0">
                                    {{ shippingAddress }}
                                </p>
                            </div>
                        </div>
                        <b-form-group
                            label-for="invoice-ref"
                        >
                            <template #label>
                                Invoice Reference ID<span class="text-danger">*</span>
                            </template>
                            <b-form-input id="invoice-ref" name="invoice-ref" :state="validateState('merchant_invoice_ref')" v-model="form.merchant_invoice_ref"></b-form-input>
                            <b-form-invalid-feedback id="invoice-ref-live-feedback">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-input-group style="gap: 8px;">
                            <b-form-group
                                label-for="invoice-date"
                                class="flex-fill"
                            >
                                <template #label>
                                    Invoice Date<span class="text-danger">*</span>
                                </template>
                                <date-picker
                                    type="datetime"
                                    format="D MMM, YYYY HH:mm"
                                    id="invoice-date"
                                    name="invoice-date"
                                    prefix-class="xmx"
                                    class="w-100"
                                    v-model="form.invoice_date"
                                    :class="{'is-invalid': $v.form.invoice_date.$error}"
                                ></date-picker>
                                    <!-- :disabled-date="disabledBeforeToday" -->
                                <b-form-invalid-feedback id="invoice-date-live-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group
                                label="Due Date"
                                label-for="due-date"
                                class="flex-fill"
                            >
                                <template #label>
                                    Due Date<span class="text-danger">*</span>
                                </template>
                                <date-picker
                                    type="datetime"
                                    format="D MMM, YYYY HH:mm"
                                    id="due-date"
                                    name="due-date"
                                    prefix-class="xmx"
                                    class="w-100"
                                    v-model="form.due_date"
                                    :class="{'is-invalid': $v.form.due_date.$error}"
                                    @input="dueDateChanged"
                                    :disabled-date="disabledBeforeInvoiceDate"
                                ></date-picker>
                                <b-form-invalid-feedback id="due-date-live-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                        </b-input-group>
                        <b-form-group
                            label="Invoice Description"
                            label-for="invoice-description"
                            id="field-invoice-description"
                            description="Maximum 200 characters are allowed."
                        >
                            <b-form-input maxlength="200" id="invoice-description" name="invoice-description" :state="validateState('description')" v-model="form.description"></b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox
                                id="custom_field"
                                name="custom_field"
                                :value="true"
                                @change="customFieldsToggle"
                                :unchecked-value="false"
                                v-model="showCustomFields"
                            >
                                Add Custom Fields <span style="color: rgba(0, 0, 0, 0.30);">(Optional)</span>
                            </b-form-checkbox>

                            <div v-show="showCustomFields">
                                <div
                                    v-for="(data, index) in form.notes"
                                    :key="index"
                                >
                                    <div class="d-flex mt-3 mb-2" style="gap: 10px;">
                                        <input
                                            v-model="data.key"
                                            type="text"
                                            class="form-control w-25"
                                            placeholder="Title"
                                            @keyup="reset_add_field_error"
                                            name="title"
                                        />
                                        <input
                                            v-model="data.value"
                                            type="text"
                                            class="form-control"
                                            placeholder="Description"
                                            @keyup="reset_add_field_error"
                                            name="description"
                                        />
                                        <button class="btn p-0" @click="removeCustomField(index)">
                                            <img src="@/assets/images/delete.svg" alt="edit" />
                                        </button>
                                    </div>
                                    <b-form-checkbox
                                        :value="true"
                                        :unchecked-value="false"
                                        v-model="data.show_note"
                                        class="ml-3"
                                    >
                                        <small>Show this custom field on the invoice.</small>
                                    </b-form-checkbox>
                                </div>
                                <small v-show="add_fields_error" class="error">Title and Description can not be empty</small>
                                <b-btn size="sm" variant="link" class="text-chai d-block p-0 mt-2" @click="addCustomField">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M4 8H12M8 12V4" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    Add New
                                </b-btn>
                            </div>
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox
                                id="send_email_confirmation"
                                v-model="form.send_email_confirmation"
                                name="send_email_confirmation"
                                class="text-primary"
                                >
                                {{ $t("views.payment_pages.email_confirmation_send") }}
                            </b-form-checkbox>
                        </b-form-group>

                        <hr style="margin: 25px 0;">

                        <payment-method-modal class="mb-3" @payment-methods="storePaymentMethods" :currency="form.currency" />

                        <b-form-group>
                            <b-form-checkbox
                                name="qr_image"
                                :value="true"
                                :unchecked-value="false"
                                v-model="form.show_qr_code"
                            >
                                Attach QR Code Image to the invoice
                            </b-form-checkbox>
                        </b-form-group>
                        <hr style="margin: 25px 0;">
                        <b-input-group>
                            <div class="w-50">
                                <b-form-checkbox
                                    name="schedule_invoice_date"
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="scheduleInvoice"
                                >
                                    Schedule invoice date
                                </b-form-checkbox>
                                <div v-show="scheduleInvoice">
                                    <date-picker
                                        type="datetime"
                                        format="D MMM, YYYY HH:mm"
                                        id="scheduled-date"
                                        name="scheduled-date"
                                        prefix-class="xmx"
                                        class="w-100 mt-2"
                                        v-model="form.scheduled_date"
                                        :class="{'is-invalid': $v.form.due_date.$error}"
                                        :disabled-date="disabledAfterDueDate"
                                    ></date-picker>
                                    <b-form-invalid-feedback id="scheduled-date-live-feedback">This is a required field.</b-form-invalid-feedback>
                                </div>
                            </div>
                            <b-form-checkbox
                                name="send_immediately"
                                :value="true"
                                :unchecked-value="false"
                                v-model="form.send_immediately"
                            >
                                Send invoice immediately
                            </b-form-checkbox>
                        </b-input-group>
                        <hr style="margin: 25px 0;">
                        <b-form-group>
                            <b-form-checkbox
                                name="set_reminders"
                                :value="true"
                                :unchecked-value="false"
                                v-model="setReminder"
                            >
                                Set Reminders
                            </b-form-checkbox>
                        </b-form-group>
                        <b-input-group v-show="setReminder" style="gap: 8px;">

                            <b-dropdown variant="white">
                                <template v-slot:button-content>
                                    <div class="d-flex justify-content-between" style="gap: 15px;">
                                        <p class="mb-0">{{ form.reminder_time }}</p>
                                        <img class="mr-0" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
                                    </div>
                                </template>

                                <b-dropdown-item
                                    v-for="(option, i) in timeOptions"
                                    :key="`timeOption${i}`"
                                    @click="timeChanged(option)"
                                    :link-class="{ 'active': option === form.reminder_time, 'd-flex flex-wrap': true }"
                                >
                                    {{ option }}
                                </b-dropdown-item>
                            </b-dropdown>

                            <b-dropdown variant="white" menu-class="w-100" class="flex-fill">
                                <template v-slot:button-content>
                                    <div class="d-flex justify-content-between" style="gap: 15px;">
                                        <p class="mb-0">{{ reminderTimeUnit }}</p>
                                        <img class="mr-0" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
                                    </div>
                                </template>

                                <b-dropdown-item
                                    v-for="(option, i) in unitOptions"
                                    :key="`unitOption${i}`"
                                    :disabled="option.disabled"
                                    @click="unitChanged(option)"
                                    :link-class="{ 'active': option === form.reminder_time_unit, 'd-flex flex-wrap': true }"
                                >
                                    {{ option.text }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group>
                        <!-- <div style="margin-top: 0.25rem; font-size: 80%; color: #ff3d60;" v-if="showReminderError && setReminder">Please set reminder according to due date</div> -->

                        <!-- <b-btn size="sm" variant="link" class="text-chai" style="margin-top: 16px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M4 8H12M8 12V4" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Add New
                        </b-btn> -->
                    </div>
                </div>

                <div>
                    <div class="invoice-summary">
                        <h5 class="text-chai">Invoice Summary</h5>

                        <b-input :value="(totalAmount()).toLocaleString('en-US', { style: 'currency', currency: form.currency })" readonly style="width: 354px;"></b-input>

                        <hr style="margin: 25px 0;">
                        
                        <h6>Customer Details</h6>

                        <div class="details-grid">
                            <p>Name</p>
                            <p>{{ form.customer_details.name }}</p>

                            <p>Email</p>
                            <p>{{ form.customer_details.email_address }}</p>

                            <p>Contact Number</p>
                            <p>{{ phoneNumber && phoneNumber.formattedNumber ? phoneNumber.formattedNumber : "" }}</p>
                        </div>

                        <hr style="margin: 8px 0 16px;">

                        <h6>Invoice Details</h6>

                        <div class="details-grid">
                            <p>Invoice#</p>
                            <p>{{ form.merchant_invoice_ref }}</p>

                            <p>Created At</p>
                            <p>{{ form.invoice_date | date }}</p>

                            <p>Due Date</p>
                            <p>{{ form.due_date | date }}</p>
                        </div>

                        <hr style="margin: 8px 0 16px;">

                        <h6>Addresses</h6>

                        <div class="details-grid">
                            <p>Billing Address</p>
                            <p>{{ billingAddress }}</p>

                            <p v-if="form.show_shipping_details">Shipping Address</p>
                            <p v-if="form.show_shipping_details">{{ shippingAddress }}</p>
                        </div>

                        <div v-show="showCustomFields">
                            <hr style="margin: 8px 0 16px;">
    
                            <h6>Custom Fields</h6>
    
                            <div class="details-grid" v-for="(note, index) in form.notes" :key="index">
                                <p>{{ note.key }}</p>
                                <p>{{ note.value }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-header">
                <p class="section-title">Line Items</p>
                <p class="section-text">Create New Invoice to collect payment from your customers.</p>
            </div>
            <div class="container-fluid" id="lineItems">
                <b-row class="line-items">
                    <b-col md="5" style="padding-left: 24px;">ITEM DETAILS</b-col>
                    <b-col>RATE</b-col>
                    <b-col>QUANTITY</b-col>
                    <b-col md="3">AMOUNT</b-col>
                </b-row>
                <draggable :list="form.order_details" class="list-group" handle=".handle">
                    <b-row class="line-items" style="font-weight: 400;" v-for="(item, index) in form.order_details" :key="index">
                        <b-col md="5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none" class="handle">
                                <ellipse cx="1.28875" cy="1.2" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                                <ellipse cx="6.44402" cy="1.2" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                                <ellipse cx="1.28875" cy="5.9998" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                                <ellipse cx="6.44402" cy="5.9998" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                                <ellipse cx="1.28875" cy="10.7996" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                                <ellipse cx="6.44402" cy="10.7996" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                            </svg>
                            <input v-model="item.name" placeholder="Type here for an item detail">
                        </b-col>
                        <b-col><b-form-input type="number" @mousewheel.prevent v-model.number="item.price" placeholder="0"></b-form-input></b-col>
                        <b-col><b-form-input type="number" @mousewheel.prevent v-model.number="item.quantity" placeholder="0"></b-form-input></b-col>
                        <b-col md="3">
                            <div class="d-flex">
                                <span class="flex-fill text-right mr-2 my-auto">{{ (item.price * item.quantity).toLocaleString("en-US", { style: 'currency', currency: form.currency }) }}</span>
                                <b-dropdown variant="white-theme" dropleft>
                                    <template v-slot:button-content>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M6.66699 12.6667C6.66699 13.4 7.26699 14 8.00033 14C8.73366 14 9.33366 13.4 9.33366 12.6667C9.33366 11.9333 8.73366 11.3333 8.00033 11.3333C7.26699 11.3333 6.66699 11.9333 6.66699 12.6667ZM6.66699 3.33333C6.66699 4.06667 7.26699 4.66667 8.00033 4.66667C8.73366 4.66667 9.33366 4.06667 9.33366 3.33333C9.33366 2.6 8.73366 2 8.00033 2C7.26699 2 6.66699 2.6 6.66699 3.33333ZM6.66699 8C6.66699 8.73333 7.26699 9.33333 8.00033 9.33333C8.73366 9.33333 9.33366 8.73333 9.33366 8C9.33366 7.26667 8.73366 6.66667 8.00033 6.66667C7.26699 6.66667 6.66699 7.26667 6.66699 8Z" stroke="black"/>
                                        </svg>
                                    </template>
                                    <b-dropdown-item-button @click="cloneItem(index)">Clone</b-dropdown-item-button>
                                </b-dropdown>
        
                                <button class="btn p-0" @click="removeItem(index)" :disabled="form.order_details.length === 1">
                                    <img src="@/assets/images/delete.svg" alt="edit" />
                                </button>
                            </div>
                        </b-col>
                    </b-row>
                </draggable>
                <div v-if="itemDetailsError" class="invalid-feedback d-inline">Invalid Item details. Item details cannot be empty.</div>
                <b-row class="my-4">
                    <b-col class="d-flex flex-column justify-content-between">
                        <div>
                            <b-btn size="sm" variant="link" class="text-chai" @click="addItem">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M4 8H12M8 12V4" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Add New Line
                            </b-btn>
                        </div>
                        <template>
                            <template v-if="!editing">
                                <div class="custom-note-title-label">
                                    {{ this.form.customer_note_title }}
                                    <span id="editIcon" @click="startEditing">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="vuesax/linear/edit-2">
                                            <g id="edit-2">
                                            <path id="Vector" d="M8.83958 2.39982L3.36624 8.19315C3.15958 8.41315 2.95958 8.84649 2.91958 9.14649L2.67291 11.3065C2.58624 12.0865 3.14624 12.6198 3.91958 12.4865L6.06624 12.1198C6.36624 12.0665 6.78624 11.8465 6.99291 11.6198L12.4662 5.82649C13.4129 4.82649 13.8396 3.68649 12.3662 2.29315C10.8996 0.913152 9.78624 1.39982 8.83958 2.39982Z" stroke="#FC6B2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path id="Vector_2" d="M7.92578 3.36621C8.21245 5.20621 9.70578 6.61288 11.5591 6.79954" stroke="#FC6B2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path id="Vector_3" d="M2 14.667H14" stroke="#FC6B2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span v-if="this.form.customer_note_title !== 'Customer Note'" class="reset-customer-notes" @click="resetFields">Reset</span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="customer_note_title_parent">
                                    <b-form-input class="customer_note_title" placeholder="Customer Notes" v-model="editedCustomerNoteTitle"></b-form-input>
                                    <p class="edit-save" @click="saveChanges" >Save</p>
                                    <p class="edit-cancel" @click="cancelEditing">Cancel</p>
                                </div>
                            </template>
                            <b-form-textarea
                                name="customer-notes"
                                class="customer-notes-value"
                                placeholder="Thank you for your business!"
                                rows="5"
                                max-rows="6"
                                v-model="form.customer_note_value"
                            ></b-form-textarea>
                            <p class="custom-note-description">This message will be displayed on the invoice.</p>
                        </template>
                    </b-col>
                    <b-col>
                        <div style="border-radius: 12px; background: rgba(220, 218, 213, 0.30); padding: 24px 4px 24px 32px;">
                            <div class="d-flex justify-content-between" style="font-size: 14px; font-weight: 400; line-height: 20px; margin-bottom: 24px; margin-right: 56px;">
                                <p class="mb-0">Sub Total</p>
                                <p class="mb-0">{{ subTotal.toLocaleString("en-US", { style: 'currency', currency: form.currency }) }}</p>
                            </div>

                            <div class="d-flex justify-content-between" v-for="(cost, index) in form.additional_costs" :key="index">
                                <span>{{ cost.name }}</span>
                                <div class="d-flex">
                                    <p class="my-auto">{{ cost.type == 'DISCOUNT' ? '-' : '' }} {{ (cost.percent ? ((cost.amount * subTotal) / 100) : cost.amount).toLocaleString("en-US", { style: 'currency', currency: form.currency }) }}</p>
                                    <div class="text-right" style="width: 56px;">
                                        <button class="btn p-0" @click="editAdditionalCost(index)">
                                            <img src="@/assets/images/edit-2.svg" alt="edit" />
                                        </button>
                                        <button class="btn p-0" @click="removeAdditionalCost(index)">
                                            <img src="@/assets/images/delete.svg" alt="delete" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div v-show="additionalCost.visible" class="my-2 font-14px" style="margin-right: 56px;">
                                <div class="d-flex mb-2" style="gap: 8px;">
                                    <b-form-input v-model="additionalCost.name" style="width: 40%;border-radius: 8px; border: 1px solid #DCDAD5; background: #FFF;" placeholder="Title" size="sm"></b-form-input>
                                    <b-input-group size="sm">
                                        <template #prepend>
                                            <b-dropdown variant="zinc" size="sm">
                                                <template v-slot:button-content>
                                                    <span class="text-chai my-auto">{{ additionalCost.type === "CHARGE" ? '+' : '-' }}</span>
                                                    <img class="my-auto" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
                                                </template>
                                                <b-dropdown-item @click="() => additionalCost.type = 'CHARGE'">+</b-dropdown-item>
                                                <b-dropdown-item @click="() => additionalCost.type = 'DISCOUNT'">-</b-dropdown-item>
                                            </b-dropdown>
                                        </template>
                                        <b-form-input type="number" @mousewheel.prevent min="0" size="sm" v-model.number="additionalCost.amount"></b-form-input>
                                        <template #append>
                                            <b-dropdown variant="zinc" size="sm">
                                                <template v-slot:button-content>
                                                    <span class="text-chai my-auto">{{ additionalCost.percent ? '%' : currencySymbol }}</span>
                                                    <img class="my-auto" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
                                                </template>
                                                <b-dropdown-item @click="() => additionalCost.percent = true">%</b-dropdown-item>
                                                <b-dropdown-item @click="() => additionalCost.percent = false">{{ currencySymbol }}</b-dropdown-item>
                                            </b-dropdown>
                                        </template>
                                    </b-input-group>
                                    <span class="w-25 my-auto text-right">
                                        {{ (additionalCost.percent ? additionalCost.amount * subTotal / 100 : additionalCost.amount).toFixed(2) }}
                                    </span>
                                </div>
                                <div class="d-block">
                                    <b-btn variant="primary" @click="addAdditionalCost" size="sm" class="font-12px">save</b-btn>
                                    <b-btn variant="light" size="sm" @click="resetAdditionalCost" style="background: transparent !important; color: #000;" class="ml-2 border-0 font-12px">Cancel</b-btn>
                                </div>
                            </div>

                            <b-btn size="sm" variant="link" class="text-chai" @click="addCost">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M4 8H12M8 12V4" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Add Discounts/Charges/Tax
                            </b-btn>

                            <hr class="my-4" style="margin-right: 56px;">

                            <div class="d-flex justify-content-between" style="font-size: 16px; font-weight: 400; line-height: 24px; margin-right: 56px;">
                                <p class="mb-0">Total Amount ( {{ currencySymbol }} )</p>
                                <p class="mb-0">{{ totalAmount().toLocaleString("en-US", { style: 'currency', currency: form.currency }) }}</p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <hr/>
                <template>
                    <div class="p-0" style="border-radius: 12px;">
                        <b-form-group
                            id="field-terms-conditions"
                            label-for="terms-conditions"
                            class="mb-0"
                            description="Add additional info that will be displayed on the invoice."
                        >
                            <template #label>
                                Additional Notes
                            </template>
                            
                        </b-form-group>
                        <div v-for="(note, index) in form.additional_invoice_notes" :key="index">
                            <b-form-group>
                                <b-form-input class="w-50 custom_notes_title" @keyup="reset_add_field_error" v-model="note.title" placeholder="Title"></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <div class="additional-notes-description">
                                <b-form-textarea class="custom_notes_description" @keyup="reset_add_field_error" v-model="note.description" placeholder="Description" ></b-form-textarea>
                                <span class="remove-additional-note" @click="removeNote(index)" > 
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Iconsax/Linear/add">
                                        <path id="Vector" d="M7.24264 16.2426L15.7279 7.75736M15.7279 16.2426L7.24264 7.75736" stroke="#E36464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg>

                                </span>
                                </div>
                            </b-form-group>
                            <div v-if="index !== form.additional_invoice_notes.length-1" class="line"></div>
                        </div>
                        <small v-show="add_fields_error1" class="error">Title and Description can not be empty</small>
                        <p class="add-additional-note" @click="addNote">+ Add Additional Note</p>
                        <!-- <div class="flex-fill"></div> -->
                        <!-- <div style="border-left: 1px solid #DCDAD5;">
                            <b-form-group
                                class="my-auto pl-3"
                                label="Attach Files to Invoice"
                                label-for="file_upload"
                                id="field_file_upload"
                                description="You can upload maximum of 2 files, 5MB each."
                            >
                                <div class="custom-file b-form-file">
                                    <input type="file" id="file_upload" class="custom-file-input" style="z-index: -5;">
                                    <label for="file_upload" class="custom-file-label d-flex justify-content-center align-items-center" style="gap: 8px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M12.3304 6.675C15.0304 6.9075 16.1329 8.295 16.1329 11.3325V11.43C16.1329 14.7825 14.7904 16.125 11.4379 16.125H6.55535C3.20285 16.125 1.86035 14.7825 1.86035 11.43V11.3325C1.86035 8.3175 2.94785 6.93 5.60285 6.6825M9.00035 11.25V2.715M11.5129 4.3875L9.00035 1.875L6.48785 4.3875" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        Upload File
                                    </label>
                                </div>
                            </b-form-group>
                        </div> -->
                    </div>
                </template>
            </div>
        </form>

        <b-modal
            id="address-modal"
            no-close-on-backdrop
            hide-header
            hide-footer
            @show="resetAddress"
            content-class="address_modal"
        >
            <div class="d-flex justify-content-between">
                <p class="section-title">Billing Address</p>
                <CloseModalBtn @close-button-clicked="$bvModal.hide('address-modal')" />
            </div>
            <p class="section-text pb-0">Choose from your existing templates.</p>
            <div class="pt-4 pb-2" style="height: 75vh; overflow: hidden auto;">
                <b-form-group label="Country">
                    <Country :country="billing_address.country_code" @country-changed="billingAddressCountryChanged" />
                </b-form-group>
                <b-form-group label="Address">
                    <b-form-input v-model="billing_address.line_1" placeholder="Street 1" class="mb-2"></b-form-input>
                    <b-form-input v-model="billing_address.line_2" placeholder="Street 2"></b-form-input>
                </b-form-group>
                <b-form-group label="City">
                    <b-form-input v-model="billing_address.city" placeholder="City"></b-form-input>
                </b-form-group>
                <div class="row">
                    <b-form-group label="State" class="col">
                        <b-form-input v-model="billing_address.state" placeholder="State"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Zip Code" class="col">
                        <b-form-input v-model="billing_address.postal_code" placeholder="Zip Code"></b-form-input>
                    </b-form-group>
                </div>

                <hr style="margin: 25px 0;">
    
                <b-form-group>
                    <b-form-checkbox
                        :value="true"
                        :unchecked-value="false"
                        v-model="form.show_shipping_details"
                    >
                        Add shipping address to the invoice
                    </b-form-checkbox>
                </b-form-group>
    
                <div v-if="form.show_shipping_details">
                    <p class="section-title pb-0">Shipping Address</p>
        
                    <div class="pt-4 pb-2">
                        <b-form-group label="Country">
                            <Country :country="shipping_address.country_code" @country-changed="shippingAddressCountryChanged" />
                        </b-form-group>
                        <b-form-group label="Address">
                            <b-form-input v-model="shipping_address.line_1" placeholder="Street 1" class="mb-2"></b-form-input>
                            <b-form-input v-model="shipping_address.line_2" placeholder="Street 2"></b-form-input>
                        </b-form-group>
                        <b-form-group label="City">
                            <b-form-input v-model="shipping_address.city" placeholder="City"></b-form-input>
                        </b-form-group>
                        <div class="row">
                            <b-form-group label="State" class="col">
                                <b-form-input v-model="shipping_address.state" placeholder="State"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Zip Code" class="col">
                                <b-form-input v-model="shipping_address.postal_code" placeholder="Zip Code"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>

            <div style="gap: 12px;" class="d-flex justify-content-end">
              <b-btn variant="primary" @click="$bvModal.hide('address-modal')" class="bg-white text-dark cancel border-0">Cancel</b-btn>
              <b-btn type="submit" @click="saveAddress" variant="primary" class="save">Save</b-btn>
            </div>
        </b-modal>
        <b-modal
            id="preview-modal"
            no-close-on-backdrop
            hide-header
            hide-footer
            size="lg"
            @shown ="()=> this.pdfHeight = this.$refs.pdfPreviewHeight && this.$refs.pdfPreviewHeight.clientWidth ? this.$refs.pdfPreviewHeight.clientWidth * 1.41 : ''"
        >
            <div class="d-flex justify-content-end">
                <CloseModalBtn @close-button-clicked="$bvModal.hide('preview-modal')" />
            </div>

            <div>
                <div ref="pdfPreviewHeight">
                    <invoice-pdf-preview :invoiceDetails="form" :style="{ 'min-height':  pdfHeight ? pdfHeight +'px' : 'auto' }" />
                </div>
            </div>
        </b-modal>
    </Layout>
</template>

<style lang="scss" scoped>
.main-section {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 15px 1px rgba(220, 218, 213, 0.30);
}
::v-deep form, .modal-body {
    input {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
    }
    label {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
    .form-control {
        border-radius: 12px;
        border: 1px solid #DCDAD5;
        height: 44px;
        padding: 13px 16px;
    }
    [type=email] {
        padding-left: 42px;
        border-radius: 12px !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cg opacity='0.3'%3E%3Cpath d='M12.75 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V11.625C16.5 14.25 15 15.375 12.75 15.375Z' stroke='black' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.75 6.75L10.4025 8.625C9.63 9.24 8.3625 9.24 7.59 8.625L5.25 6.75' stroke='black' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
        background-position: left 16px center;
        background-repeat: no-repeat;
    }
    [type=email].is-invalid {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cg opacity='0.3'%3E%3Cpath d='M12.75 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V11.625C16.5 14.25 15 15.375 12.75 15.375Z' stroke='black' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.75 6.75L10.4025 8.625C9.63 9.24 8.3625 9.24 7.59 8.625L5.25 6.75' stroke='black' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff3d60' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff3d60' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
        background-position: left 16px center, right calc(0.25em + 0.235rem) center;
        background-repeat: no-repeat, no-repeat;
    }
    [type=email].is-valid {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cg opacity='0.3'%3E%3Cpath d='M12.75 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V11.625C16.5 14.25 15 15.375 12.75 15.375Z' stroke='black' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.75 6.75L10.4025 8.625C9.63 9.24 8.3625 9.24 7.59 8.625L5.25 6.75' stroke='black' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231cbb8c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-position: left 16px center, right calc(0.25em + 0.235rem) center;
        background-repeat: no-repeat, no-repeat;
    }
    .is-invalid .xmx-input {
        border-color: #ff3d60 !important;
    }

    [name=send_sms] + .custom-control-label {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 325;
    }
    small {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 325;
        line-height: 20px;
    }
    #field-invoice-description small:before {
        color: #FC6B2D;
        content: "Note: ";
    }
    #field_file_upload small {
        font-size: 10px;
    }
    .xmx-input {
        border-radius: 12px;
        border: 1px solid #DCDAD5;
        background: #FFF;
        height: 44px;
    }
    :not(#lineItems) > .dropdown-toggle {
        gap: 26px;
        height: 44px;
        padding: 13px 16px;
    }
    .dropdown-toggle {
        border-radius: 8px;
        border: 1px solid #DCDAD5;
    }

    #lineItems {
        .dropdown-toggle {
            display: flex;
            gap: 4px;
            height: 32px;
            padding: 0px 8px;
        }
    }

    .dropdown-item {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
        max-height: 50vh;
        overflow-y: scroll;

        a {
            height: 44px;
            .active {
                border-right: 5px solid #FC6B2D;
                background: rgba(220, 218, 213, 0.30);
            }
        }
    }
    .custom-file-label::after {
        content: none !important;
    }
    .custom-file-label {
        border-radius: 8px;
        border: 2px dashed #DCDAD5;
        background: #FFF;
        height: 44px;
        line-height: 20px;
        font-size: 12px;
    }
    .custom-file, .custom-file-input {
        height: 44px;
        line-height: 20px;
        font-size: 12px;
    }
}
::v-deep .modal-body {
    padding: 35px;
}
.line-items {
    border-bottom: 1px solid #DCDAD5;

    > div {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        padding-left: 0;
        padding-right: 0;
    }
    > div:not(:first-child) {
        text-align: center;
    }

    &:not(:first-child) {
        > div {
            padding: 0;
        }
    }

    > div {
        height: 44px;
        font-size: 14px;
        line-height: 18px;
    }
    > div:not(:last-child) {
        border-right: 1px solid #DCDAD5;
    }
    > div:first-child {
        input {
            width: calc(100% - 8px);
        }
    }
    > div:last-child {
        input {
            text-align: end;
        }
    }
    > div:not(:first-child, :last-child) {
        input {
            width: 100%;
            text-align: end;
        }
    }
    input {
        border: none;
        height: 100%;
        outline: none;
        padding: 12px 16px;

        &::placeholder {
            color: rgba(0, 0, 0, 0.30);
            font-size: 14px;
            font-style: normal;
            font-weight: 325;
            line-height: 20px;
        }
    }
}
.section-header {
    border: 1px solid rgba(220, 218, 213, 0.50);
}
.invoice-summary {
    border-radius: 12px;
    border: 1px solid rgba(220, 218, 213, 0.50);
    background: #FFF;
    box-shadow: 0px 4px 15px 1px rgba(220, 218, 213, 0.30);
    padding: 25px 32px;
    width: 418px;

    h5 {
        font-size: 20px;
        font-style: normal;
        font-weight: 350;
        margin-bottom: 25px;
    }

    input {
        border-radius: 12px;
        border: 1px solid #DCDAD5;
        height: 58px;
        padding: 16px 24px;
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 350;
        line-height: 24px;
    }

    h6 {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
        margin-bottom: 16px;
    }

    p {
        color: rgba(0, 0, 0, 0.50);
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 16px;
        margin-bottom: 8px;
    }
    p:nth-child(even) {
        text-align: right;
    }
    .details-grid {
        display: grid;
        grid-template-columns: auto auto;
    }
}
.form-control-sm {
    height: 32px !important;
}
.input-group-sm {
    height: 32px !important;
}
::v-deep .btn-white-theme {
    height: 24px !important;
    padding: 4px !important;
    border: none !important;
    border-radius: 4px !important;
    background: rgba(220, 218, 213, 0.30) !important;
}
::v-deep .custom-control {
    z-index: unset;
}
.save {
    border: none;
    color: #FC6B2D;
    background: rgba(220, 218, 213, 0.30) !important;
}
.address {
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
}
#editIcon{
    cursor: pointer;
}
.customer_note_title_parent{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.customer_note_title{
    width: 50%;
    margin-bottom: 5px;
    margin-top: 10px;
}
.edit-save{
    margin: 0px 10px; 
    color: #FC6B2D;
    font-size: 12px;
    font-family: Gotham;
    font-weight: 350;
    line-height: 20px;
    word-wrap: break-word;
    cursor: pointer;
}
.edit-cancel{
    margin: 0px 5px; 
    cursor: pointer;
    color: black;
    font-size: 12px;
    font-family: Gotham;
    font-weight: 350;
    line-height: 20px;
    word-wrap: break-word
}
.custom-note-description{
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    font-family: Gotham;
    font-weight: 325;
    line-height: 20px;
    word-wrap: break-word
}
.custom-note-title-label{
    color: black;
    font-size: 14px;
    font-family: Gotham;
    font-weight: 350;
    line-height: 20px;
    word-wrap: break-word;
    margin-bottom: 5px;
    margin-top: 10px;
}
#field-terms-conditions{
    color: black;
    font-size: 16px;
    font-family: Gotham;
    font-weight: 350;
    line-height: 20px;
    word-wrap: break-word
}
.add-additional-note{
    color: #FC6B2D;
    font-size: 14px;
    font-family: Gotham;
    font-weight: 350;
    line-height: 20px;
    word-wrap: break-word;
    margin-top: 10px;
    cursor: pointer;
}
.additional-notes-description{
    display: flex;
    align-items: center;
}
.remove-additional-note{
    color:#FC6B2D;
    margin-left: 5px;
    cursor: pointer;
}
.line {
  height: 2px;
  width: 50px; /* Adjust the width as needed */
  background-color: #DCDAD580; /* Change color if needed */
  margin: 0px 0px 15px 5px;
}
.reset-customer-notes{
    margin-left: 8px;
    color: #FC6B2D;
    font-size: 12px;
    font-family: Gotham;
    font-weight: 350;
    line-height: 20px;
    word-wrap: break-word;
    cursor: pointer;
}
.custom_notes_title{
    font-weight: 400;
}
.customer-notes-value{
    color: black;
    font-weight: 350;
    line-height: 20px;
    word-wrap: break-word
}
.custom_notes_description{
    color: black;
    font-weight: 350;
    line-height: 20px;
    word-wrap: break-word
}
</style>
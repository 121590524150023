<script>
import Common from '../helpers/Common';

export default {
    props: {
        currency: String,
        currencies: {
            type: Array,
            default: () => Common.currencyList()
        },
        unselect: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedCurrency: ""
        }
    },
    created() {
        this.selectedCurrency = this.currencies.includes(this.currency) ? this.currency : "";
    },
    watch: {
        currency(value) {
            this.selectedCurrency = this.currencies.includes(value) ? value : "";
        }
    },
    methods: {
        currencyChanged(value) {
            this.selectedCurrency = value;
            this.$emit('currency-changed', this.selectedCurrency);
        },
    }
}
</script>

<template>
    <b-dropdown variant="white" dropup menu-class="w-100" class="w-100 currency">
        <template v-slot:button-content>
            <p class="mb-0">{{ selectedCurrency || '-- Select Currency --' }}</p>
            <img class="mr-0" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
        </template>

        <b-dropdown-item v-if="unselect" :link-class="{'active': !selectedCurrency, 'd-flex': true}" @click="currencyChanged('')">
            -- Select Currency --
        </b-dropdown-item>

        <b-dropdown-item
            v-for="(option, i) in currencies"
            :key="`currency${i}`"
            @click="currencyChanged(option)"
            :link-class="{'active': option === selectedCurrency, 'd-flex': true}"
        >
            <div class="my-auto">
                {{ option }}
            </div>
        </b-dropdown-item>
    </b-dropdown>
</template>

<style lang="scss" scoped>
::v-deep .dropdown-toggle {
    display: flex;
    height: 44px;
    padding: 18px 25px;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid #DCDAD5;
}

::v-deep .dropdown-menu {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    max-height: 50vh;
    overflow-y: scroll;
    min-width: min-content;

    a {
        height: 44px;
        .active {
            border-right: 5px solid #FC6B2D;
            background: rgba(220, 218, 213, 0.30);
        }
    }
}
</style>